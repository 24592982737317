(function () {
  angular
    .module('kmi.lms.course.common')
    .constant('userDetailsSections', {
      rightPanel: {
        courseFormats: [131, 132],
      },
    })
    .factory('contentSectionsService', function (courseEnums, globalConfig, _, userDetailsSections) {
      return {
        checkSectionIsAvailable: checkSectionIsAvailable,
        checkSubSectionIsAvailable: checkSubSectionIsAvailable,
        getSection: getSection,
        getSubSection: getSubSection,
        checkSubSectionLocked: checkSubSectionLocked,
      };

      function getSection(section, view) {
        // For user config, not for admin
        if (typeof section === 'string') {
          if (view === 'userDetails') {
            section = _.get(userDetailsSections, section);
          } else {
            section = _.get(globalConfig, 'adminContentSections.' + section);
          }
        }

        return section;
      }

      function getSubSection(section, subSection, view) {
        // For user config, not for admin
        if (typeof subSection === 'string') {
          subSection = _.get(getSection(section, view), 'subSection.' + subSection);
        }

        return subSection;
      }

      function checkSubSectionLocked(course, sectionName, subSectionName) {
        let subSection = getSubSection(sectionName, subSectionName, true);
        if (!subSection) {
          return true;
        }

        return (
          _.includes(subSection.lockedCourseFormats, course.format) ||
          (subSection.allowedCourseFormats && !_.includes(subSection.allowedCourseFormats, course.format))
        );
      }

      function checkSubSectionIsAvailable(course, section, subSection, view) {
        // This method should unify check for field in one place in admin view and admin details
        let subSectionItem = getSubSection(section, subSection, view);
        if (!subSectionItem) {
          // If field not in field restrictions, it is available for all
          return true;
        }

        return checkSectionItemIsAvailable(course, subSectionItem);
      }

      function checkSectionIsAvailable(course, section, view) {
        let sectionItem = getSection(section, view);

        return checkSectionItemIsAvailable(course, sectionItem);
      }

      function checkSectionItemIsAvailable(course, item) {
        // Check visibility
        if (!item || item.enable === false) {
          return false;
        }

        if (
          item.environment &&
          item.environment !== 'local' &&
          item.environment &&
          !_.includes(item.environment, globalConfig.environment)
        ) {
          return false;
        }

        if (item.registrationRequired !== undefined && item.registrationRequired && course && course.courseFormat) {
          if (item.registrationRequired !== course.courseFormat.registrationRequired) {
            return false;
          }
        }

        if (item.requiredSettings !== undefined && item.requiredSettings) {
          for (var setting in item.requiredSettings) {
            if (_.get(globalConfig, setting) !== item.requiredSettings[setting]) {
              return false;
            }
          }
        }

        return !(
          (item.courseFormats !== undefined && item.courseFormats && !_.includes(item.courseFormats, course.format)) ||
          (item.courseFormatTypes !== undefined &&
            item.courseFormatTypes &&
            !_.includes(item.courseFormatTypes, course.formatTypeId)) ||
          (item.fieldsHasValue !== undefined &&
            item.fieldsHasValue &&
            item.fieldsHasValue.some((itemField) => !fieldHasValue(course, itemField))) ||
          (item.exceptCourseFormats !== undefined &&
            item.exceptCourseFormats &&
            _.includes(item.exceptCourseFormats, course.format)) ||
          (item.exceptCourseFormatTypes !== undefined &&
            item.exceptCourseFormatTypes &&
            _.includes(item.exceptCourseFormatTypes, course.formatTypeId))
        );
      }

      function fieldHasValue(course, fieldName) {
        // Check if the field exists in the course object
        if (!course || !(fieldName in course)) {
          return false;
        }

        const value = course[fieldName];

        // Check for null or undefined values
        if (value === null || value === undefined) {
          return false;
        }

        // Check for empty string
        if (typeof value === 'string' && value.trim() === '') {
          return false;
        }

        // Check for empty array
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }

        // For other types (numbers, non-empty strings, non-empty arrays, objects), return true
        return true;
      }
    });
})();
